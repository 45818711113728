import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Seo from "@components/seo"
import Main from '@components/main'
import '@styles/object/project/business.scss'

const Business = () => {

  return (
    <Main>
      <Seo title="開発実績" />
      <article className="business">
        <div className="business__container-border">
          <div className="business__container">
            <h1 className="business__title">開発実績</h1>
            <div className="business__sub-container">
              <ul className="business__project">
                <li>
                  <h2>工事管理システム</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/01_kouji.png" alt="kouji" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">5G基地局の設置条件の選定や施工会社への工事の発注、進捗を管理するシステム。要件定義および、フロントエンド、バックエンドの開発を担当。</dd>
                    <dd className="business__project__tech">技術要素：Java、JavaScript、PostgreSQL、Spring、Vue.js、MyBatis、Azure、Web</dd>
                  </dl>
                </li>
                <li>
                  <h2>基幹システム統合</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/02_integ.png" alt="kikan" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">企業合併に伴う基幹システムの統合・改修。Web画面の開発やRESTAPIによる外部システム連携を担当。</dd>
                    <dd className="business__project__tech">技術要素：Java、JavaScript、Oracle、Web</dd>
                  </dl>
                </li>
                <li>
                  <h2>オンラインゲーム</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/03_onlinegame.png" alt="onlinegame" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">オンラインゲーム本体、およびイベント運用系システム、ポータルサイトの開発を包括的に担当。</dd>
                    <dd className="business__project__tech">技術要素：C++、HTML、CSS、Web</dd>
                  </dl>
                </li>
                <li>
                  <h2>車載メンテナンスシステム</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/04_caremb.png" alt="car embed" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">エンジン制御や電子機器などの状態をチェックするリアルタイムOSによるシステム。</dd>
                    <dd className="business__project__tech">技術要素：C、RTOS、組み込み</dd>
                  </dl>
                </li>
                <li>
                  <h2>音声認識上映システム</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/05_playcontent.png" alt="playcontent" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">音声認識により動的に動画コンテンツを切り替え可能なシステム。</dd>
                    <dd className="business__project__tech">技術要素：TypeScript、Python、GoogleCloud、機械学習、音声認識</dd>
                  </dl>
                </li>
                <li>
                  <h2>音響装置ファームウェア</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/06_soundfirm.png" alt="soundfirmware" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">ユーザー操作や端末（スマホやPC）からの指示を受け取り、音響装置を動かすファームウェア。</dd>
                    <dd className="business__project__tech">技術要素：C、Linux、組み込み</dd>
                  </dl>
                </li>
                <li>
                  <h2>映像配信システム</h2>
                  <dl>
                    <dd>
                      <StaticImage src="../images/business/07_streaming.png" alt="soundfirmware" placeholder="tracedSVG" />
                    </dd>
                    <dd className="business__project__description">映像配信サービス向けに映像や音声をリアルタイムに調整して配信するサービスの開発。</dd>
                    <dd className="business__project__tech">技術要素：Java、Python、Angular.js、WebRTC、AWS</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </Main>
  )
}

export default Business
